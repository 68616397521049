import Link from 'next/link'
import { useRouter } from 'next/router'

import { Head } from '../components'
import { Locale } from '../types'
import { makeLocale } from '../utils/locale'

type Translation = {
  subline: string
  headline: string
  description: string
  linkText: string
}
type Translations = {
  [key in Locale]: Translation
}
const translations: Translations = {
  sv: {
    headline: 'Hoppsan! Jag tror att du är vilse.',
    subline: '404 fel',
    description: 'Det verkar som om sidan du letar efter inte finns.',
    linkText: 'Gå tillbaka till startsidan',
  },
  en: {
    headline: 'Uh oh! I think you’re lost.',
    subline: '404 error',
    description: "It looks like the page you’re looking for doesn't exist.",
    linkText: 'Go back home',
  },
}

export default function NotFoundPage() {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const translation = translations[locale as Locale]
  return (
    <>
      <Head title={translation.subline} />
      <main
        className="min-h-screen bg-cover bg-top sm:bg-top"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75")',
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">
            {translation.subline}
          </p>
          <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
            {translation.headline}
          </h1>
          <p className="mt-2 text-lg font-medium text-black text-opacity-50">
            {translation.description}
          </p>
          <div className="mt-6">
            <Link href="/">
              <a className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50">
                {translation.linkText}
              </a>
            </Link>
          </div>
        </div>
      </main>
    </>
  )
}
